import {
  PARTS_BOTTOM,
  PARTS_LEFT,
  PARTS_RIGHT,
  PARTS_TOP,
  PC_BASE_BLUEPRINT,
  PC_STATUS_BLUEPRINT,
  PC_ADD_BLUEPRINT,
  PC_VALID_DAYS,
} from '../../constants/PerformanceCheckConstants';
import React, { forwardRef, useEffect, useState } from 'react';
import { myInfoSelector } from '../../recoil/selectors';
import { useRecoilValueLoadable } from 'recoil';
// import { CERTIFICATE_DATAS } from '../../constants/InsuranceDataConstants';
import { CommonUtil } from '../../utils/commonUtil';
// import { EW_CERTIFICATE_DATAS } from '../../constants/EWDataConstants';
import { ServiceProvider } from '../../services';
// import { YN } from '../../constants/Constants';
import _ from 'lodash';
import dayjs from 'dayjs';

import Checked from '../../assets/icons/checked.webp';
import Unchecked from '../../assets/icons/unchecked.webp';
import BP from '../../assets/images/bp.webp';
import BPBottom from '../../assets/images/bp-bottom.webp';
import BPLeft from '../../assets/images/bp-left.webp';
import BPRight from '../../assets/images/bp-right.webp';
import BPTop from '../../assets/images/bp-top.webp';
import MainImage from '../../assets/images/pcReportMain.webp';
import IconCar1 from '../../assets/images/icon-car1.svg';
import IconCar2 from '../../assets/images/icon-car2.svg';
import IconCar3 from '../../assets/images/icon-car3.svg';
import IconCar4 from '../../assets/images/icon-car4.svg';
import IconCar5 from '../../assets/images/icon-car5.svg';
import IconStatus1 from '../../assets/images/icon-status1.svg';
import IconStatus2 from '../../assets/images/icon-status2.svg';
import IconStatus3 from '../../assets/images/icon-status3.svg';
import IconStatus4 from '../../assets/images/icon-status4.svg';
import IconStatus5 from '../../assets/images/icon-status5.svg';
import IconStatus6 from '../../assets/images/icon-status6.svg';

const baseTitles = _.mapValues(PC_BASE_BLUEPRINT, 'title');
const baseValueItems = _.mapValues(PC_BASE_BLUEPRINT, 'valueItems');
const statusTitles = _.mapValues(PC_STATUS_BLUEPRINT, 'title');
const statusValueItems = _.mapValues(PC_STATUS_BLUEPRINT, 'valueItems');
const addTitles = _.mapValues(PC_ADD_BLUEPRINT, 'title');
const addValueItems = _.mapValues(PC_ADD_BLUEPRINT, 'valueItems');

const associationService = ServiceProvider.association;
const userService = ServiceProvider.user;
const performanceCheckService = ServiceProvider.performanceCheck;

const setCheckState = (raw, key, compare) => {
  if (_.isArray(compare))
    return _.includes(compare, _.get(raw, key)) ? Checked : Unchecked;
  return _.get(raw, key) === compare ? Checked : Unchecked;
};

const setSubCheckState = (raw, keys, compares) => {
  let count = 0;
  for (const key of keys) {
    if (_.includes(compares, _.get(raw, key))) count++;
  }
  return count === 0 ? Unchecked : Checked;
};

const setPartState = (value) => {
  let label = '';

  switch (value) {
    case '1':
      return '';
    case '2':
      return 'X';
    case '3':
      return 'W';
    case '4':
      return 'C';
    case '5':
      return 'A';
    case '6':
      return 'U';
    case '7':
      return 'T';
    default:
      break;
  }

  return label;
};

const PerformanceCheckReport = forwardRef(
  (
    {
      data,
      addData,
      photos,
      payment,
      association,
      inspector,
      customerCompany,
      vehicleInformation,
    },
    ref
  ) => {
    const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
    const insurancePolicyNumber =
      myInfoLoadable.contents.shopInfo?.insurancePolicyNumber;
    const [officialSealUrls, setOfficialSealUrls] = useState({});
    const [isAvailable, setIsAvailable] = useState({
      certificate: false,
      ew: false,
    });
    const [totalPage, setTotalPage] = useState('');
    const [imagePages, setImagePages] = useState([]);
    const totalValues = {
      checkData: data,
      addData: addData,
      photos,
      paymentInformation: payment,
      association,
      inspector,
      vehicleInformation,
      insurancePolicyNumber,
    };

    async function getEncodedImgUrl(imageUrl) {
      // return process.env.REACT_APP_S3_BASE_URL + imageUrl;
      return 'https://d1bit06empsjbp.cloudfront.net/' + imageUrl;
    }
    async function getAssociationSealUrl(association) {
      const { associationId } = association;
      try {
        const {
          data: { officialSealPath },
        } = await associationService.getDataMobile(associationId);

        if (officialSealPath) {
          const encodedUrl = await getEncodedImgUrl(officialSealPath);
          setOfficialSealUrls((ps) => ({ ...ps, association: encodedUrl }));
        }
      } catch (error) {
        console.log('official seal load fail');
      }
    }

    async function getUserSealUrl(inspector) {
      const { userId } = inspector;
      try {
        const {
          data: { officialSealURL },
        } = await userService.getDataExternal(userId);

        if (officialSealURL) {
          const encodedUrl = await getEncodedImgUrl(officialSealURL);
          setOfficialSealUrls((ps) => ({ ...ps, inspector: encodedUrl }));
        }
      } catch (error) {
        console.log('official seal load fail');
      }
    }

    function renderSignImages() {
      const [association, shop] = _.get(data, 'CHCK_ENTRPS_NM').split('/');

      return (
        <div className="vertical-align-bottom">
          <span
            className="text-lg"
            style={{
              position: 'relative',
              paddingRight: '60px',
            }}
          >
            {association}
            <img
              crossOrigin="anonymous"
              src={`${officialSealUrls.association}?timestamp=${Date.now()}`}
              alt="association_officialSeal"
              style={{
                position: 'absolute',
                width: '50px',
                top: '-50%',
                right: '5px',
              }}
            />
          </span>
          <span className="text-lg mr-4">{`/ ${shop} `}</span>
          <span className="text-lg mr-4">{_.get(data, 'INSCTR_NM')}</span>
          <span
            style={{
              position: 'relative',
            }}
          >
            (서명 또는 인)
            {officialSealUrls.inspector && (
              <img
                crossOrigin="anonymous"
                src={`${officialSealUrls.inspector}?timestamp=${Date.now()}`}
                alt="inspector_officialSeal"
                style={{
                  position: 'absolute',
                  width: '50px',
                  top: '-120%',
                  left: 0,
                }}
              />
            )}
          </span>
        </div>
      );
    }

    function importCertImage(insuranceCode) {
      try {
        return [
          {
            front: require(`../../assets/images/insurance/GUIDE_00${insuranceCode}_01.jpg`),
            rear: require(`../../assets/images/insurance/GUIDE_00${insuranceCode}_02.jpg`),
          },
          true,
        ];
      } catch (error) {
        console.log(error);
        return [
          {
            front: null,
            rear: null,
          },
          false,
        ];
      }
    }

    // function renderInsuranceCertificate(insuranceCode) {
    //   const [urls] = importCertImage(insuranceCode);
    //   const { front, rear } = urls;

    //   const datas = _.filter(CERTIFICATE_DATAS, (data) => {
    //     return _.get(data, 'insuranceCompanyCode').indexOf(insuranceCode) >= 0;
    //   });

    //   // const datas = _.filter(CERTIFICATE_DATAS, (data) => {
    //   //   return _.get(data, 'insuranceCompanyCode').indexOf('10') >= 0;
    //   // });

    //   return (
    //     <div className="paper_container">
    //       <div className="paper_wrapper">
    //         <div
    //           crossOrigin="anonymous"
    //           className="paper"
    //           key={`insurance_certificate_page_${insuranceCode}_front`}
    //         >
    //           <table className="w-full">
    //             <thead />
    //             <tbody>
    //               <tr className="h-1rem">
    //                 <td className="text-xs" />
    //                 <td className="text-xs text-right">{`(5 / ${totalPage}쪽)`}</td>
    //               </tr>
    //               <tr>
    //                 <td
    //                   colSpan={2}
    //                   className="content"
    //                   style={{ position: 'relative' }}
    //                 >
    //                   <img
    //                     src={front}
    //                     alt="insurance_certificate_front"
    //                     style={{
    //                       width: '100%',
    //                       height: 'calc(100% -4px)',
    //                     }}
    //                   />
    //                   {datas.map((data) => {
    //                     const [top, left] = _.get(data.pos, insuranceCode);
    //                     // const [top, left] = _.get(data.pos, '10');
    //                     return (
    //                       <span
    //                         key={data.objName + data.name}
    //                         className="font-bold"
    //                         style={{
    //                           position: 'absolute',
    //                           top: top,
    //                           left: left,
    //                           color: data.color || 'black',
    //                         }}
    //                       >
    //                         {/* {_.get(
    //                           totalValues,
    //                           `${data.objName}.${data.fieldName}`
    //                         )} */}
    //                         {data.getValue(totalValues)}
    //                       </span>
    //                     );
    //                   })}
    //                 </td>
    //               </tr>
    //             </tbody>
    //           </table>
    //         </div>
    //       </div>
    //       <div className="paper_wrapper">
    //         <div
    //           className="paper"
    //           key={`insurance_certificate_page_${insuranceCode}_rear`}
    //         >
    //           <table className="w-full">
    //             <thead />
    //             <tbody>
    //               <tr className="h-1rem">
    //                 <td className="text-xs" />
    //                 <td className="text-xs text-right">{`(6 / ${totalPage}쪽)`}</td>
    //               </tr>
    //               <tr>
    //                 <td
    //                   colSpan={2}
    //                   className="content"
    //                   style={{ position: 'relative' }}
    //                 >
    //                   <img
    //                     src={rear}
    //                     alt="insurance_certificate_rear"
    //                     style={{
    //                       width: '100%',
    //                       height: 'calc(100% -4px)',
    //                     }}
    //                   />
    //                 </td>
    //               </tr>
    //             </tbody>
    //           </table>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }

    // function importEwImage(insuranceCode) {
    //   const isEw = _.get(totalValues, 'paymentInformation.ewYN') === YN.YES;
    //   try {
    //     return [
    //       {
    //         front: require(`../../assets/images/ew/EW_00${insuranceCode}_01.png`),
    //         rear: require(`../../assets/images/ew/EW_00${insuranceCode}_02.png`),
    //       },
    //       true && isEw,
    //     ];
    //   } catch (error) {
    //     console.log(error);
    //     return [
    //       {
    //         front: null,
    //         rear: null,
    //       },
    //       false,
    //     ];
    //   }
    // }

    // function renderEwInsuranceCertificate(insuranceCode) {
    //   const [urls] = importEwImage(insuranceCode);
    //   const { front, rear } = urls;

    //   const datas = _.filter(EW_CERTIFICATE_DATAS, (data) => {
    //     return _.get(data, 'insuranceCompanyCode').indexOf(insuranceCode) >= 0;
    //   });

    //   return (
    //     <div className="paper_container">
    //       <div className="paper_wrapper">
    //         <div
    //           className="paper"
    //           key={`ew_insurance_certificate_page_${insuranceCode}_front`}
    //           // style={{
    //           //   width: '100%',
    //           //   height: '100%',
    //           // }}
    //         >
    //           <table className="w-full">
    //             <thead />
    //             <tbody>
    //               <tr className="h-1rem">
    //                 <td className="text-xs" />
    //                 <td className="text-xs text-right">{`(7 / ${totalPage}쪽)`}</td>
    //               </tr>
    //               <tr>
    //                 <td
    //                   colSpan={2}
    //                   className="content"
    //                   style={{
    //                     position: 'relative',
    //                     // width: '100%',
    //                     // height: '100%',
    //                   }}
    //                 >
    //                   <img
    //                     src={front}
    //                     alt="ew_insurance_certificate_front"
    //                     style={{
    //                       width: '100%',
    //                       height: 'calc(100% -4px)',
    //                     }}
    //                   />
    //                   {datas.map((data) => {
    //                     const [top, left] = _.get(data.pos, insuranceCode);
    //                     return (
    //                       <span
    //                         key={data.objName + data.name}
    //                         className={`${data.styleClass}`}
    //                         style={{
    //                           position: 'absolute',
    //                           top: top,
    //                           left: left,
    //                           color: data.color || 'black',
    //                         }}
    //                       >
    //                         {data.getValue(totalValues)}
    //                       </span>
    //                     );
    //                   })}
    //                 </td>
    //               </tr>
    //             </tbody>
    //           </table>
    //         </div>
    //       </div>
    //       <div className="paper_wrapper">
    //         <div
    //           className="paper"
    //           key={`ew_insurance_certificate_page_${insuranceCode}_rear`}
    //         >
    //           <table className="w-full">
    //             <thead />
    //             <tbody>
    //               <tr className="h-1rem">
    //                 <td className="text-xs" />
    //                 <td className="text-xs text-right">{`(8 / ${totalPage}쪽)`}</td>
    //               </tr>
    //               <tr>
    //                 <td
    //                   colSpan={2}
    //                   className="content"
    //                   style={{ position: 'relative' }}
    //                 >
    //                   <img
    //                     src={rear}
    //                     alt="ew_insurance_certificate_front"
    //                     style={{
    //                       width: '100%',
    //                       height: 'calc(100% -4px)',
    //                     }}
    //                   />
    //                   {datas
    //                     .filter((data) => data.fieldName === 'enterpriseName')
    //                     .map((data) => {
    //                       const [top, left] = _.get(data.pos, insuranceCode);
    //                       return (
    //                         <span
    //                           key={data.objName + data.name}
    //                           className={`${data.styleClass}`}
    //                           style={{
    //                             position: 'absolute',
    //                             top: top,
    //                             left: left,
    //                             color: data.color || 'black',
    //                           }}
    //                         >
    //                           {data.getValue(totalValues)}
    //                         </span>
    //                       );
    //                     })}
    //                 </td>
    //               </tr>
    //             </tbody>
    //           </table>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }

    const CheckboxGroup = ({ options, data, setCheckState, itemKey }) => {
      return (
        <div className="flex flex-row align-items-center justify-content-start gap-3">
          {options &&
            options.map((option, index) => (
              <div
                key={`chk_${itemKey}_${index}`}
                className="flex flex-row align-items-center justify-content-start gap-1"
              >
                <img
                  className="cbi"
                  alt={`checkbox for ${option.label}`}
                  src={setCheckState(data, itemKey, option.value)}
                />
                {option.label}
              </div>
            ))}
        </div>
      );
    };

    const TableRow = ({
      className = '',
      colSpan = 2,
      title,
      itemKey,
      options,
      data,
      setCheckState,
    }) => (
      <>
        <td className={className} colSpan={colSpan}>
          {title}
        </td>
        <td>
          <CheckboxGroup
            itemKey={itemKey}
            options={options}
            data={data}
            setCheckState={setCheckState}
          />
        </td>
      </>
    );

    const formatDate = (date) => {
      if (!date) return '년     월     일';
      return dayjs(date).format('YYYY 년 MM 월 DD 일');
    };

    const getEndDate = (startDate) => {
      if (!startDate) return '년     월     일';
      return dayjs(startDate)
        .add(PC_VALID_DAYS, 'day')
        .format('YYYY년 MM월 DD일');
      // return dayjs(startDate).add(2, 'month').format('YYYY 년 MM 월 DD 일');
    };

    function calculateImagePages(images) {
      // 페이지 당 이미지 수
      const IMAGES_PER_PAGE = 8;
      // 페이지 수 계산
      const totalPages = Math.ceil(images.length / IMAGES_PER_PAGE);
      // 이미지를 페이지 단위로 나눔
      const newimagePages = [];
      for (let i = 0; i < totalPages; i++) {
        newimagePages.push(
          images.slice(i * IMAGES_PER_PAGE, (i + 1) * IMAGES_PER_PAGE)
        );
      }

      return newimagePages;
    }

    useEffect(() => {
      (async () => {
        if (association !== null) {
          await getAssociationSealUrl(association);
        }

        if (inspector !== null) {
          await getUserSealUrl(inspector);
        }
      })();
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [association, inspector]);

    // useEffect(() => {
    //   if (payment) {
    //     const { ICNY_CODE } = payment;
    //     const [certificateUrl, isCertificateAvailable] =
    //       importCertImage(ICNY_CODE);
    //     const [ewUrl, isEwAvailable] = importEwImage(ICNY_CODE);

    //     setIsAvailable({
    //       certificate: isCertificateAvailable,
    //       ew: isEwAvailable,
    //     });

    //     let totalPage = 4;

    //     if (isCertificateAvailable) {
    //       totalPage += 2;
    //     }

    //     if (isEwAvailable) {
    //       totalPage += 2;
    //     }

    //     setTotalPage(String(totalPage));
    //   }
    //   //eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [payment]);

    useEffect(() => {
      (async () => {
        if (photos && photos.length > 0) {
          // 한독은 모든 이미지 보이게 한다고 함
          async function getFormattedImages(images) {
            return await Promise.all(
              images.map(async (img) => {
                const encodedUrl = await getEncodedImgUrl(
                  _.get(img, 'filePath')
                );
                const alt = _.get(img, 'partCode');

                img.src = encodedUrl;
                img.alt = alt || '기타';

                return img;
              })
            );
          }

          const formattedImages = await getFormattedImages(photos);
          setImagePages(calculateImagePages(formattedImages));
        }
      })();
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photos]);

    return (
      <div ref={ref} id="performance-check-report" className="pcr">
        {/* 1페이지 */}
        <div className="paper_container">
          <div className="paper_wrapper">
            <div className="paper">
              <div className="relative">
                <img
                  src={MainImage}
                  alt="HD 프리미엄 중고차 성능 상태 기록부"
                  className="main__img"
                />
                <div
                  className="absolute text-2xl font-bold"
                  style={{ top: '270px', left: '60px' }}
                >
                  <p>점검일자 : {formatDate(data?.CHCK_DE)}</p>
                </div>
              </div>

              {/* @차량의 기본정보 */}
              <div className="section_title">
                <img src={IconCar1} alt="차량의 기본정보" className="mr-3" />
                <p>차량의 기본정보</p>
              </div>
              <table className="table">
                <tbody>
                  <tr>
                    <td className="title">1. 차명</td>
                    <td>{_.get(data, 'CNM')}</td>
                    <td className="title">2. 차량번호</td>
                    <td>{_.get(data, 'VHRNO')}</td>
                  </tr>
                  <tr>
                    <td className="title">3. 연식</td>
                    <td>{_.get(data, 'PRYE')}</td>
                    <td className="title">4. 검사 유효기간</td>
                    <td>
                      {dayjs(_.get(data, 'INSPT_VALID_PD_BGNDE')).format(
                        'YYYY년 MM월 DD일'
                      )}{' '}
                      ~{' '}
                      {dayjs(_.get(data, 'INSPT_VALID_PD_ENDDE')).format(
                        'YYYY년 MM월 DD일'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="title">5. 최초등록일</td>
                    <td>
                      {dayjs(_.get(data, 'FRST_REGIST_DE')).format(
                        'YYYY년 MM월 DD일'
                      )}
                    </td>
                    <td rowSpan="2" className="title">
                      6. 변속기종류
                    </td>
                    <td rowSpan="2">
                      <div className="flex flex-column">
                        <div className="flex flex-row align-items-center justify-content-start gap-3">
                          <div className="flex flex-row align-items-center justify-content-start gap-1">
                            <img
                              className="cbi"
                              alt="checkbox"
                              src={setCheckState(data, 'GRBX_KND_CODE', 'A')}
                            />
                            자동
                          </div>
                          <div className="flex flex-row align-items-center justify-content-start gap-1">
                            <img
                              className="cbi"
                              alt="checkbox"
                              src={setCheckState(data, 'GRBX_KND_CODE', 'M')}
                            />
                            수동
                          </div>
                          <div className="flex flex-row align-items-center justify-content-start gap-1">
                            <img
                              className="cbi"
                              alt="checkbox"
                              src={setCheckState(data, 'GRBX_KND_CODE', 'S')}
                            />
                            세미오토
                          </div>
                        </div>
                        <div className="mt-2 flex flex-row align-items-center justify-content-start gap-3">
                          <div className="flex flex-row align-items-center justify-content-start gap-1">
                            <img
                              className="cbi"
                              alt="checkbox"
                              src={setCheckState(data, 'GRBX_KND_CODE', 'C')}
                            />
                            무단변속기
                          </div>
                          <div className="flex flex-row align-items-center justify-content-start gap-1">
                            <img
                              className="cbi"
                              alt="checkbox"
                              src={setCheckState(data, 'GRBX_KND_CODE', 'X')}
                            />
                            기타 (
                            {_.get(data, 'GRBX_KND_DTLS') ||
                              '\u00A0\u00A0\u00A0\u00A0'}
                            )
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">7. 차대번호</td>
                    <td>
                      {_.get(data, 'VIN').length === 11
                        ? `${_.get(data, 'VIN')}${_.get(
                            vehicleInformation,
                            'vinRemain'
                          )}`
                        : `${_.get(data, 'VIN')}`}
                    </td>
                  </tr>
                  <tr>
                    <td className="title">8. 사용연료</td>
                    <td colSpan="3">
                      <CheckboxGroup
                        itemKey="USE_FUEL_CODE"
                        options={baseValueItems['USE_FUEL_CODE']}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="title">9. 보증유형</td>
                    <td
                      colSpan="3"
                      style={{
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}
                    >
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'ASSRNC_TY_SE_CODE', '2')}
                          />
                          보험사보증 (성능보증이 가능한 차량)
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'ASSRNC_TY_SE_CODE', '1')}
                          />
                          보험사비보증 (20만Km 이상)
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">10. 원동기형식</td>
                    <td colSpan="3">{_.get(data, 'MTRS_FOM')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* 2페이지 */}
        <div className="paper_container">
          <div className="paper_wrapper">
            <div className="paper">
              {/* @자동차 상세정보 */}
              <div className="section_title mt-6">
                <img src={IconCar2} alt="자동차 상세 정보" className="mr-2" />
                <p>자동차 상세 정보</p>
              </div>
              <table className="table">
                <colgroup>
                  <col width="18%" />
                  <col width="32%" />
                  <col width="18%" />
                  <col width="32%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="title">11. 사용이력</td>
                    <td className="title text-center">상태</td>
                    <td className="title text-center" colSpan="2">
                      항목
                    </td>
                  </tr>
                  <tr>
                    <td className="title">
                      주행거리 상태
                      <br />
                      (1년 2만Km 기준)
                    </td>
                    <td>
                      <CheckboxGroup
                        itemKey="TRVL_DSTNC_STTUS_CODE"
                        options={baseValueItems['TRVL_DSTNC_STTUS_CODE']}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </td>
                    <td className="title">
                      현재 주행거리
                      <br />
                      (검사시 계시판 기준)
                    </td>
                    <td>
                      <span className="font-bold">
                        {CommonUtil.Unit.format(_.get(data, 'TRVL_DSTNC'))}
                      </span>
                      &nbsp; KM
                    </td>
                  </tr>
                  <tr>
                    <td className="title">계기 상태</td>
                    <td colSpan="3">
                      <CheckboxGroup
                        itemKey="GAUGE_FNCT_AT"
                        options={baseValueItems['GAUGE_FNCT_AT']}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="title">차대번호 표기</td>
                    <td colSpan="3">
                      <CheckboxGroup
                        itemKey="SAMENSS_CNFIRM_CODE"
                        options={baseValueItems['SAMENSS_CNFIRM_CODE']}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="title">배출가스</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(
                              data,
                              'CHK_CRMN_MESURE_VALUE',
                              'Y'
                            )}
                          />
                          일산화탄소
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(
                              data,
                              'CHK_HYDR_MESURE_VALUE',
                              'Y'
                            )}
                          />
                          탄화수소
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(
                              data,
                              'CHK_SMOKE_MESURE_VALUE',
                              'Y'
                            )}
                          />
                          매연
                        </div>
                      </div>
                    </td>
                    <td colSpan="2">
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <span>
                          {_.get(data, 'CRMN_MESURE_VALUE') === ''
                            ? '0.0'
                            : _.get(data, 'CRMN_MESURE_VALUE')}{' '}
                          %,
                        </span>
                        <span>
                          {_.get(data, 'HYDR_MESURE_VALUE') === ''
                            ? '0'
                            : _.get(data, 'HYDR_MESURE_VALUE')}{' '}
                          ppm,
                        </span>
                        <span>
                          {_.get(data, 'SMOKE_MESURE_VALUE') === ''
                            ? '0'
                            : _.get(data, 'SMOKE_MESURE_VALUE')}{' '}
                          %
                        </span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="title">튜닝</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'UNLAW_STMD_AT', '0')}
                          />
                          없음
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'UNLAW_STMD_AT', [
                              '1',
                              '2',
                            ])}
                          />
                          있음
                        </div>
                      </div>
                    </td>
                    <td colSpan="2">
                      <CheckboxGroup
                        itemKey="UNLAW_STMD_AT"
                        options={baseValueItems['UNLAW_STMD_AT']}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="title">특별이력</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'FLUD_AT', '0')}
                          />
                          없음
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'FLUD_AT', ['1', '2'])}
                          />
                          있음
                        </div>
                      </div>
                    </td>
                    <td colSpan="2">
                      <CheckboxGroup
                        itemKey="FLUD_AT"
                        options={baseValueItems['FLUD_AT']}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className="title">용도변경</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(
                              data,
                              'PRPOS_CHANGE_SE_CODE',
                              '0'
                            )}
                          />
                          없음
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'PRPOS_CHANGE_SE_CODE', [
                              '1',
                              '2',
                              '3',
                            ])}
                          />
                          있음
                        </div>
                      </div>
                    </td>
                    <td colSpan="2">
                      <CheckboxGroup
                        itemKey="PRPOS_CHANGE_SE_CODE"
                        options={baseValueItems['PRPOS_CHANGE_SE_CODE']}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </td>
                  </tr>
                  {/* TODO 차량 색상, 주요옵션 */}
                  {/* <tr>
                    <td className="title">색상</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'COLOR_AT', '1')}
                          />
                          무채색
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'COLOR_AT', '2')}
                          />
                          유채색
                        </div>
                      </div>
                    </td>
                    <td colSpan="2">
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'COLOR_CHANGE_AT', '1')}
                          />
                          전체도색
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'COLOR_CHANGE_AT', '2')}
                          />
                          색상변경
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">주요옵션</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'TODO', '1')}
                          />
                          없음
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'TODO', '1')}
                          />
                          있음
                        </div>
                      </div>
                    </td>
                    <td colSpan="2">
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(
                              data,
                              'MAIN_OPTN_VHCLE_RF_AT',
                              '1'
                            )}
                          />
                          썬루프
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(
                              data,
                              'MAIN_OPTN_ROAD_GUIDANCE_AT',
                              '1'
                            )}
                          />
                          네비게이션
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'MAIN_OPTN_ETC_AT', '1')}
                          />
                          기타
                        </div>
                      </div>
                    </td>
                  </tr> */}
                  <tr>
                    <td className="title">리콜대상</td>
                    <td>
                      <CheckboxGroup
                        itemKey="RECALL_TRGET_AT"
                        options={baseValueItems['RECALL_TRGET_AT']}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </td>
                    <td className="title">리콜이행</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={
                              _.get(data, 'RECALL_TRGET_AT') === '1'
                                ? setCheckState(data, 'RECALL_FLFL_AT', '1')
                                : Unchecked
                            }
                          />
                          이행
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={
                              _.get(data, 'RECALL_TRGET_AT') === '1'
                                ? setCheckState(data, 'RECALL_FLFL_AT', '0')
                                : Unchecked
                            }
                          />
                          미이행
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* @ 차량 사고 교환 수리 이력 */}
              <div className="section_title mt-7">
                <img
                  src={IconCar3}
                  alt="차량 사고 교환 수리 이력"
                  className="mr-2"
                />
                <p>차량 사고 교환 수리 이력</p>
              </div>
              <table className="table">
                <colgroup>
                  <col width="18%" />
                  <col width="32%" />
                  <col width="18%" />
                  <col width="32%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="bg-gray-100" colSpan="4">
                      <div className="flex flex-row align-items-center justify-content-start">
                        <div className="mr-2">※ 상태표시 부호 :</div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <div className="flex flex-row align-items-center justify-content-start gap-1">
                            <img src={IconStatus1} alt="교환" />
                            교환
                          </div>
                          <div className="flex flex-row align-items-center justify-content-start gap-1">
                            <img src={IconStatus2} alt="판금 또는 용접" />
                            판금 또는 용접
                          </div>
                          <div className="flex flex-row align-items-center justify-content-start gap-1">
                            <img src={IconStatus3} alt="부식" />
                            부식
                          </div>
                          <div className="flex flex-row align-items-center justify-content-start gap-1">
                            <img src={IconStatus4} alt="흠집" />
                            흠집
                          </div>
                          <div className="flex flex-row align-items-center justify-content-start gap-1">
                            <img src={IconStatus5} alt="요철" />
                            요철
                          </div>
                          <div className="flex flex-row align-items-center justify-content-start gap-1">
                            <img src={IconStatus6} alt="손상" />
                            손상
                          </div>
                        </div>
                      </div>
                      ※ 하단 항목은 승용차 기준이며, 기타 자동차는 승용차에
                      준하여 표시
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="11" className=" p-0">
                      <div className="flex flex-row align-items-center justify-content-evenly relative">
                        <div className="relative">
                          <img
                            src={BPLeft}
                            style={{
                              minHeight: '70mm',
                              height: '70mm',
                              maxHeight: '70mm',
                            }}
                            className="m-0 p-0 opacity-70"
                            alt={'차량이미지'}
                          />
                          {_.map(
                            PARTS_LEFT,
                            ({ partPoint, rpos: [top, left] }) => (
                              <div
                                key={`key_${partPoint}`}
                                // className="cerp-pp absolute text-center text-base font-bold"
                                className="cerp-pp absolute text-center text-xl font-bold"
                                style={{ top, left }}
                              >
                                {setPartState(_.get(data, partPoint))}
                              </div>
                            )
                          )}
                        </div>
                        <div className="relative">
                          <img
                            src={BPTop}
                            style={{
                              minHeight: '70mm',
                              height: '70mm',
                              maxHeight: '70mm',
                            }}
                            className="m-0 p-0 opacity-70"
                            alt={'차량이미지'}
                          />
                          {_.map(
                            PARTS_TOP,
                            ({ partPoint, rpos: [top, left] }) => (
                              <div
                                key={`key_${partPoint}`}
                                // className="cerp-pp absolute text-center text-base font-bold"
                                className="cerp-pp absolute text-center text-xl font-bold"
                                style={{ top, left }}
                              >
                                {setPartState(_.get(data, partPoint))}
                              </div>
                            )
                          )}
                        </div>
                        <div className="relative">
                          <img
                            src={BPBottom}
                            style={{
                              minHeight: '70mm',
                              height: '70mm',
                              maxHeight: '70mm',
                            }}
                            className="m-0 p-0 opacity-70"
                            // className="m-0 p-0"
                            alt={'차량이미지'}
                          />
                          {_.map(
                            PARTS_BOTTOM,
                            ({ partPoint, rpos: [top, left] }) => (
                              <div
                                key={`key_${partPoint}`}
                                // className="cerp-pp absolute text-center text-base font-bold"
                                className="cerp-pp absolute text-center text-xl font-bold"
                                style={{ top, left }}
                              >
                                {setPartState(_.get(data, partPoint))}
                              </div>
                            )
                          )}
                        </div>
                        <div className="relative">
                          <img
                            src={BPRight}
                            style={{
                              minHeight: '70mm',
                              height: '70mm',
                              maxHeight: '70mm',
                            }}
                            className="m-0 p-0 opacity-70"
                            alt={'차량이미지'}
                          />
                          {_.map(
                            PARTS_RIGHT,
                            ({ partPoint, rpos: [top, left] }) => (
                              <div
                                key={`key_${partPoint}`}
                                // className="cerp-pp absolute text-center text-base font-bold"
                                className="cerp-pp absolute text-center text-xl font-bold"
                                style={{ top, left }}
                              >
                                {setPartState(_.get(data, partPoint))}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="title">
                      12. 사고이력
                      <p className="text-xs">
                        (차량의 중요부위 사고가 있는 경우)
                      </p>
                    </td>
                    <td>
                      <CheckboxGroup
                        itemKey="ACDNT_AT"
                        options={baseValueItems['ACDNT_AT']}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </td>
                    <td className="title">
                      단순수리
                      <p className="text-xs">
                        (차량의 단순뷔위 교환, 판금 있는 경우)
                      </p>
                    </td>
                    <td>
                      <CheckboxGroup
                        itemKey="SIMPL_REPAIR_AT"
                        options={baseValueItems['SIMPL_REPAIR_AT']}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* 3페이지 */}
        <div className="paper_container">
          <div className="paper_wrapper">
            <div className="paper">
              <div className="section_title_gray">
                <img src={IconCar4} alt="" className="mr-2" />
                <p>12. 교환, 판금 등 이상 부위 상세 (위 차량 부위 참조)</p>
              </div>
              <table className="table">
                <colgroup>
                  <col width="18%" />
                  <col width="20.5%" />
                  <col width="61.5%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="title" rowSpan="2">
                      외판부위
                    </td>
                    <td className="text-center">1랭크</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's037', ['2', '3', '4'])}
                          />
                          1. 후드
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's038', ['2', '3', '4'])}
                          />
                          2. 프론트휀더
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's039', ['2', '3', '4'])}
                          />
                          3. 도어
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's040', ['2', '3', '4'])}
                          />
                          4. 트렁크 리드
                        </div>
                      </div>
                      <div className="mt-2 flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's041', ['2', '3', '4'])}
                          />
                          5. 라디에이터서포트(볼트체결부품)
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">2랭크</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's043', ['2', '3', '4'])}
                          />
                          6. 쿼터패널(리어휀더)
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's042', ['2', '3', '4'])}
                          />
                          7. 루프패널
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's044', ['2', '3', '4'])}
                          />
                          8. 사이드실패널
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td rowSpan="3" className="title">
                      주요골격
                    </td>
                    <td className="text-center">A랭크</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's045', ['2', '3', '4'])}
                          />
                          9. 프론트패널
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's046', ['2', '3', '4'])}
                          />
                          10. 크로스멤버
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's047', ['2', '3', '4'])}
                          />
                          11. 인사이드패널
                        </div>
                      </div>
                      <div className="mt-2 flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's054', ['2', '3', '4'])}
                          />
                          17. 트렁크플로어
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's053', ['2', '3', '4'])}
                          />
                          18. 리어패널
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">B랭크</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's048', ['2', '3', '4'])}
                          />
                          12. 사이드멤버
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's049', ['2', '3', '4'])}
                          />
                          13. 휠하우스
                        </div>
                      </div>
                      <div className="mt-2 flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's052', ['2', '3', '4'])}
                          />
                          14. 필러패널
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-2">
                          <div>(</div>
                          <div className="flex flex-row align-items-center justify-content-start gap-1">
                            <img
                              className="cbi"
                              alt="checkbox"
                              src={setSubCheckState(
                                data,
                                ['pp_63', 'pp_78'],
                                ['2', '3', '4']
                              )}
                            />
                            A,
                          </div>
                          <div className="flex flex-row align-items-center justify-content-start gap-1">
                            <img
                              className="cbi"
                              alt="checkbox"
                              src={setSubCheckState(
                                data,
                                ['pp_103', 'pp_118'],
                                ['2', '3', '4']
                              )}
                            />
                            B,
                          </div>
                          <div className="flex flex-row align-items-center justify-content-start gap-1">
                            <img
                              className="cbi"
                              alt="checkbox"
                              src={setSubCheckState(
                                data,
                                ['pp_144', 'pp_137'],
                                ['2', '3', '4']
                              )}
                            />
                            C
                          </div>
                          <div>)</div>
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's056', ['2', '3', '4'])}
                          />
                          19. 패키지트레이
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">C랭크</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's050', ['2', '3', '4'])}
                          />
                          15. 대쉬패널
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 's051', ['2', '3', '4'])}
                          />
                          16. 플로어패널
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="section_title_gray mt-4">
                <img
                  src={IconCar5}
                  alt="차량 주요장치 누유 및 불량 상세"
                  className="mr-2"
                />
                <p>13. 차량 주요장치 누유 및 불량 상세</p>
              </div>
              <table className="table">
                <colgroup>
                  <col width="18%" />
                  <col width="20.5%" />
                  <col width="20.5%" />
                  <col width="41%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="title">주요장치</td>
                    <td colSpan="2" className="title text-center">
                      항목 / 해당부품
                    </td>
                    <td className="title text-center">상태</td>
                  </tr>
                  <tr>
                    <td rowSpan="2" className="title">
                      자기진단
                    </td>
                    <TableRow
                      colSpan="2"
                      title={baseTitles['ENGINE_STBLT_AT']}
                      itemKey="ENGINE_STBLT_AT"
                      options={baseValueItems['ENGINE_STBLT_AT']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  <tr>
                    <TableRow
                      colSpan="2"
                      title={baseTitles['GRBX_STBLT_AT']}
                      itemKey="GRBX_STBLT_AT"
                      options={baseValueItems['GRBX_STBLT_AT']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  <tr>
                    <td className="title" rowSpan="10">
                      원동기 (엔진)
                    </td>
                    <TableRow
                      colSpan="2"
                      title={statusTitles['s001']}
                      itemKey="s001"
                      options={statusValueItems['s001']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  <tr>
                    <td rowSpan="3">오일누유</td>
                    <TableRow
                      colSpan="1"
                      title={statusTitles['s057']}
                      itemKey="s057"
                      options={statusValueItems['s057']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  {['s003', 's058'].map((item, idx) => (
                    <tr key={`tr_${item}`}>
                      <TableRow
                        colSpan="1"
                        title={statusTitles[item]}
                        itemKey={item}
                        options={statusValueItems[item]}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </tr>
                  ))}
                  <tr>
                    <TableRow
                      colSpan="2"
                      title={statusTitles['s005']}
                      itemKey="s005"
                      options={statusValueItems['s005']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  <tr>
                    <td rowSpan="4">냉각수 누수</td>
                    <TableRow
                      colSpan="1"
                      title={statusTitles['s007']}
                      itemKey="s007"
                      options={statusValueItems['s007']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  {['s009', 's008', 's010'].map((item, idx) => (
                    <tr key={`tr_${item}`}>
                      <TableRow
                        colSpan="1"
                        title={statusTitles[item]}
                        itemKey={item}
                        options={statusValueItems[item]}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </tr>
                  ))}
                  <tr>
                    <TableRow
                      colSpan="2"
                      title={statusTitles['s011']}
                      itemKey="s011"
                      options={statusValueItems['s011']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  <tr>
                    <td rowSpan="3" className="title">
                      변속기 (미션)
                    </td>
                    <td rowSpan="3">자동변속기 (A/T)</td>
                    <TableRow
                      colSpan="1"
                      title={statusTitles['s012']}
                      itemKey="s012"
                      options={statusValueItems['s012']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  {['s013', 's016'].map((item) => (
                    <tr key={`tr_${item}`}>
                      <TableRow
                        colSpan="1"
                        title={statusTitles[item]}
                        itemKey={item}
                        options={statusValueItems[item]}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* 4페이지 */}
        <div className="paper_container">
          <div className="paper_wrapper">
            <div className="paper">
              <table className="table">
                <colgroup>
                  <col width="18%" />
                  <col width="20.5%" />
                  <col width="20.5%" />
                  <col width="41%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td rowSpan="4" className="title">
                      변속기(미션)
                    </td>
                    <td rowSpan="4">수동변속기 (M/T)</td>
                    <TableRow
                      colSpan="1"
                      title={statusTitles['s018']}
                      itemKey="s018"
                      options={statusValueItems['s018']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  {['s017', 's019', 's020'].map((item, idx) => (
                    <tr key={`tr_${item}`}>
                      <TableRow
                        colSpan="1"
                        title={statusTitles[item]}
                        itemKey={item}
                        options={statusValueItems[item]}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </tr>
                  ))}
                  <tr>
                    <td rowSpan="4" className="title">
                      동력전달
                    </td>
                    <TableRow
                      colSpan="2"
                      title={statusTitles['s021']}
                      itemKey="s021"
                      options={statusValueItems['s021']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  {['s022', 's023', 's060'].map((item, idx) => (
                    <tr key={`tr_${item}`}>
                      <TableRow
                        colSpan="2"
                        title={statusTitles[item]}
                        itemKey={item}
                        options={statusValueItems[item]}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </tr>
                  ))}
                  <tr>
                    <td rowSpan="6" className="title">
                      조향
                    </td>
                    <TableRow
                      colSpan="2"
                      title={statusTitles['s024']}
                      itemKey="s024"
                      options={statusValueItems['s024']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  <tr>
                    <td rowSpan="5">작동상태</td>
                    <TableRow
                      colSpan="1"
                      title={statusTitles['s026']}
                      itemKey="s026"
                      options={statusValueItems['s026']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  {['s025', 's061', 's062', 's027'].map((item) => (
                    <tr key={`tr_${item}`}>
                      <TableRow
                        colSpan="1"
                        title={statusTitles[item]}
                        itemKey={item}
                        options={statusValueItems[item]}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </tr>
                  ))}
                  <tr>
                    <td rowSpan="3" className="title">
                      제동
                    </td>
                    <TableRow
                      colSpan="2"
                      title={statusTitles['s059']}
                      itemKey="s059"
                      options={statusValueItems['s059']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  {['s029', 's030'].map((item) => (
                    <tr key={`tr_${item}`}>
                      <TableRow
                        colSpan="2"
                        title={statusTitles[item]}
                        itemKey={item}
                        options={statusValueItems[item]}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </tr>
                  ))}
                  <tr>
                    <td rowSpan="6" className="title">
                      전기
                    </td>
                    <TableRow
                      colSpan="2"
                      title={statusTitles['s031']}
                      itemKey="s031"
                      options={statusValueItems['s031']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  {['s055', 's032', 's033', 's034', 's036'].map((item) => (
                    <tr key={`tr_${item}`}>
                      <TableRow
                        colSpan="2"
                        title={statusTitles[item]}
                        itemKey={item}
                        options={statusValueItems[item]}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </tr>
                  ))}
                  <tr>
                    <td rowSpan="3" className="title">
                      고전원 전기장치
                    </td>
                    <TableRow
                      colSpan="2"
                      title={statusTitles['s063']}
                      itemKey={'s063'}
                      options={statusValueItems['s063']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                  {['s064', 's065'].map((item) => (
                    <tr key={`tr_${item}`}>
                      <TableRow
                        colSpan="2"
                        title={statusTitles[item]}
                        itemKey={item}
                        options={statusValueItems[item]}
                        data={data}
                        setCheckState={setCheckState}
                      />
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* 5페이지 */}
        <div className="paper_container">
          <div className="paper_wrapper">
            <div className="paper">
              <table className="table">
                <colgroup>
                  <col width="18%" />
                  <col width="20.5%" />
                  <col width="20.5%" />
                  <col width="41%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="title">연료</td>
                    <TableRow
                      colSpan="2"
                      title={statusTitles['s035']}
                      itemKey={'s035'}
                      options={statusValueItems['s035']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                </tbody>
              </table>
              <div className="section_title_gray mt-4">
                14. 자동차 상품화 및 소모품 상세
              </div>
              <table className="table">
                <colgroup>
                  <col width="18%" />
                  <col width="18%" />
                </colgroup>
                <tbody>
                  {[
                    'handle',
                    'centerfascia',
                    'seat',
                    'trunk',
                    'carExterior',
                    'carPolish',
                    'carInterior',
                    'roomCleaning',
                    'carGlass',
                  ].map((item) => (
                    <tr key={`tr_${item}`}>
                      <TableRow
                        className="title"
                        colSpan="1"
                        title={addTitles[item]}
                        itemKey={item}
                        options={addValueItems[item]}
                        data={data}
                        setCheckState={setCheckState}
                      />
                      <td>{_.get(addData, `${item}Note`)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td className="title">휠</td>
                    <td colSpan="2">
                      <div className="flex align-items-center gap-3">
                        <div className="flex align-items-center gap-1 w-6">
                          운전석 앞
                          <span className="pl-6">
                            <CheckboxGroup
                              itemKey="carWheelFL"
                              options={addValueItems['carWheelFL']}
                              data={addData}
                              setCheckState={setCheckState}
                            />
                          </span>
                        </div>
                        <div className="flex align-items-center gap-1 w-6">
                          조수석 앞
                          <span className="pl-6">
                            <CheckboxGroup
                              itemKey="carWheelFR"
                              options={addValueItems['carWheelFR']}
                              data={addData}
                              setCheckState={setCheckState}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="mt-2 flex align-items-center gap-3">
                        <div className="flex align-items-center gap-1 w-6">
                          운전석 뒤
                          <span className="pl-6">
                            <CheckboxGroup
                              itemKey="carWheelRL"
                              options={addValueItems['carWheelRL']}
                              data={data}
                              setCheckState={setCheckState}
                            />
                          </span>
                        </div>
                        <div className="flex align-items-center gap-1 w-6">
                          조수석 뒤
                          <span className="pl-6">
                            <CheckboxGroup
                              itemKey="carWheelRR"
                              options={addValueItems['carWheelRR']}
                              data={data}
                              setCheckState={setCheckState}
                            />
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">타이어 트레드</td>
                    <td colSpan="2">
                      <div className="flex align-items-center gap-3">
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'tiretreadFL') < 1.6
                              ? { border: '1px solid red' }
                              : {}
                          }
                        >
                          운전석 앞
                          <span className="pl-6">
                            {_.get(addData, 'tiretreadFL')} mm
                          </span>
                        </div>
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'tiretreadFR') < 1.6
                              ? { border: '1px solid red' }
                              : {}
                          }
                        >
                          조수석 앞
                          <span className="pl-6">
                            {_.get(addData, 'tiretreadFR')} mm
                          </span>
                        </div>
                      </div>
                      <div className="mt-2 flex align-items-center gap-3">
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'tiretreadRL') < 1.6
                              ? { border: '1px solid red' }
                              : {}
                          }
                        >
                          운전석 뒤
                          <span className="pl-6">
                            {_.get(addData, 'tiretreadRL')} mm
                          </span>
                        </div>
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'tiretreadRR') < 1.6
                              ? { border: '1px solid red' }
                              : {}
                          }
                        >
                          조수석 뒤
                          <span className="pl-6">
                            {_.get(addData, 'tiretreadRR')} mm
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">브레이크 패드</td>
                    <td colSpan="2">
                      <div className="flex align-items-center gap-3">
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'brakePadFL') < 30
                              ? { border: '1px solid red' }
                              : {}
                          }
                        >
                          운전석 앞
                          <span className="pl-6">
                            {_.get(addData, 'brakePadFL')} %
                          </span>
                        </div>
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'brakePadFR') < 30
                              ? { border: '1px solid red' }
                              : {}
                          }
                        >
                          조수석 앞
                          <span className="pl-6">
                            {_.get(addData, 'brakePadFR')} %
                          </span>
                        </div>
                      </div>
                      <div className="mt-2 flex align-items-center gap-3">
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'brakePadRL') < 30
                              ? { border: '1px solid red' }
                              : {}
                          }
                        >
                          운전석 뒤
                          <span className="pl-6">
                            {_.get(addData, 'brakePadRL')} %
                          </span>
                        </div>
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'brakePadRR') < 30
                              ? { border: '1px solid red' }
                              : {}
                          }
                        >
                          조수석 뒤
                          <span className="pl-6">
                            {_.get(addData, 'brakePadRR')} %
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <img
                        src={BP}
                        style={{
                          width: '100%',
                          minHeight: '70mm',
                          height: '70mm',
                          maxHeight: '70mm',
                        }}
                        className="m-0 p-0 opacity-70"
                        alt={'차량이미지'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="title">15. 특이 사항 및 점검자 의견</td>
                    <td colSpan="3" rowSpan="5">
                      {_.get(data, 'ETC_MATTER')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* 6페이지 */}
        {imagePages.map((imageGroup, pageIndex) => (
          <div key={`page_${pageIndex}`} className="page-container">
            <div className="paper_container">
              <div className="paper_wrapper">
                <div className="paper flex flex-column">
                  <div className="section_title_gray">
                    16. 점검 장면 촬영 사진 (점검 부위 및 이상 부위 포함)
                  </div>
                  <div className="section_container flex flex-wrap justify-content-center gap-2">
                    {imageGroup.map((photo, idx) => {
                      if (!photo) return null;
                      const { originalFileName, src, alt, photoId } = photo;
                      return (
                        <img
                          key={`performance_check_img_${
                            originalFileName + idx + photoId
                          }`}
                          id={originalFileName + photoId}
                          src={`${src}?timestamp=${Date.now()}`}
                          crossOrigin="anonymous"
                          alt={alt}
                          style={{
                            width: '90mm',
                            aspectRatio: '4/3',
                            objectFit: 'cover',
                            border: '1px solid lightgray',
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* 7페이지 */}
        <div className="paper_container">
          <div className="paper_wrapper">
            <div className="paper flex flex-column">
              <div className="section_title_gray">
                17. 개인 당사자 간 거래 성능보험 유의 사항
              </div>
              <div className="section_container flex-1 w-full"></div>
              <div className="certificate">
                <p className="title">
                  HD 프리미엄 개인 당사자 간 거래를 위한 성능 점검지를
                  발급합니다.
                </p>
                <div className="date-section">
                  <div className="date">
                    <p>{formatDate(data?.CHCK_DE)}</p>
                    <p className="subtext">
                      (유효기간 : {getEndDate(data?.CHCK_DE)}까지) -
                      발급일로부터 2개월
                    </p>
                  </div>
                </div>
                <div className="signature-section">
                  <div className="signature">
                    <p>개인 직거래 상태 점검자</p>
                  </div>
                  <div className="signature">
                    <div className="flex justify-content-between">
                      <p>한국자동차(주)</p>
                      <p>(인)</p>
                      {/* <p> {renderSignImages()}</p> */}
                    </div>
                    <div className="flex justify-content-between">
                      {/* <p> {_.get(customerCompany, 'companyName')}</p> */}
                      <p>점검 책임자</p>
                      <p>(인)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 8페이지 */}
        <div className="paper_container">
          <div className="paper_wrapper">
            <div className="paper">
              <div className="section_title_gray">
                18. 개인 당사자 간 거래를 위한 자동차 성능 상태 점검 보험 가입
                확인서
              </div>
              <div className="section_container">
                <section>
                  <h2>중고차 직거래 성능보증 서비스란?</h2>
                  <p>
                    중고차 직거래 보증 서비스는 한독성능장에서 개인간 직거래를
                    위하여 당사의 점검기준(약관)에 의하여 받은 성능상태점검 경과
                    하자로 인하여 점검자가 부담하는 수리손해 위험을 보장하고자
                    한동성능장이 현대해상화재보험에 보험을 가입하여 이용고객에게
                    무료로 제공하는 보증서비스입니다.
                  </p>
                </section>
                <section>
                  <h2>계약기본사항</h2>
                  <table className="font-bold">
                    <tbody>
                      <tr>
                        <td className="text-center">종목</td>
                        <td>자동차성능상태점검 보증</td>
                        <td className="text-center">증권 NO.</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className="text-center">주행거리</td>
                        <td>
                          {CommonUtil.Unit.format(_.get(data, 'TRVL_DSTNC'))} km
                        </td>
                        <td className="text-center">점검자</td>
                        <td>한독자동차㈜ 한독성능장</td>
                      </tr>
                      <tr>
                        <td className="text-center">차량번호</td>
                        <td>{_.get(data, 'VHRNO')}</td>
                        <td className="text-center">차대번호</td>
                        <td>{_.get(data, 'VIN')}</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section>
                  <h2>보장내용 및 보장기간</h2>
                  <p>중고차 품질평가일 기준 60일 또는 주행거리 2,000km 이내</p>
                  <ol>
                    <li>
                      ① 보증기간은 중고차 품질평가일을 시작일로 하여 기간과
                      주행거리 중 먼저 도래한 것을 보증기간의 만료로 간주합니다.
                    </li>
                    <li>
                      ② 본 서비스 보증기간 60일 이내에 보장 차량이 직거래 되지
                      않은 경우 자동 종료되며, 보증기간 내 이전 등록된 경우라도
                      해당 등록일을 포함하여 60일을 초과할 수 없습니다.
                    </li>
                  </ol>
                  <p>
                    보장 차량의 품질점검 상태가 상이하여 해당 부품의
                    기능이상으로 차량정비업체에서 수리한 경우 다음과 같이 보장해
                    드립니다.
                    <br />
                    (자동차 검사 방법으로 점검이 불가한 경우는 원칙적으로
                    보증에서 제외됩니다)
                  </p>
                  <ol>
                    <li>① 국산차/외제차 1회 입고한 2천만원</li>
                    <li>
                      ② 보장 차량의 품질평가와 보장차량의 외판부위, 주요골격
                      상태가 상이한 경우 아래와 같이 보장합니다.
                    </li>
                    <table>
                      <colgroup>
                        <col width="13%" />
                        <col width="67%" />
                        <col width="10%" />
                        <col width="10%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th colSpan="2">구분</th>
                          <th>국산차</th>
                          <th>외산차</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td rowSpan="2">외판부위</td>
                          <td>
                            후드, 프론트휀더, 도어, 트렁크리드,
                            라디에이터서포트, 쿼터패널, 사이드실패널
                          </td>
                          <td className="text-center">30만원</td>
                          <td className="text-center">50만원</td>
                        </tr>
                        <tr>
                          <td className="text-left">루프패널</td>
                          <td className="text-center">40만원</td>
                          <td className="text-center">70만원</td>
                        </tr>
                        <tr>
                          <td rowSpan="2">주요골격</td>
                          <td>
                            프론트패널, 크로스멤버, 인사이드패널, 사이드멤버,
                            필러패널, 대쉬패널 트렁크플로어, 리어패널
                          </td>
                          <td className="text-center">30만원</td>
                          <td className="text-center">50만원</td>
                        </tr>
                        <tr>
                          <td className="text-left">휠하우스, 플로어패널</td>
                          <td className="text-center">40만원</td>
                          <td className="text-center">70만원</td>
                        </tr>
                      </tbody>
                    </table>
                    <li>
                      ③ A/S보증한도를 초과한 수리 비용에 대하여는 고객이
                      부담하여야 합니다.
                    </li>
                  </ol>
                </section>
                <section>
                  <h2>고객자기부담금</h2>
                  <p>
                    ① 1회 수리 입고당 - 10만원&nbsp;&nbsp; ② 외판부위, 주요 골격
                    - 국산차/외제차 10만원
                  </p>
                </section>
                <section>
                  <h2>보증제외 차량</h2>
                  <p>
                    주행거리 12만km 이상의 차량, 1톤 초과 화물차량, 버스 & 택시,
                    렌터카 및 리스 차량 등 영업 차량, 레이싱카, 퍼포먼스 차량,
                    시험테스트 차량, 특수 차량 및 이륜/삼륜 차량, 공공기관용
                    차량, 제조 12년(출고일 포함) 경과 차량은 서비스 대상에서
                    제외 됩니다.
                  </p>
                </section>
                <section>
                  <h2>보상절차 안내 [상담 및 접수센터 : ○○○○ - ○○○○ ]</h2>
                  <p>
                    보험사고 발생 → 보험사고 접수 → 지정정비소 입고 → 보상여부
                    판단 → 처리 완료 및 차량출고
                  </p>
                </section>
                <section>
                  <h5>
                    직거래 성능보증에 대한 보증범위, 보증수리, 보상제외 등에
                    대한 세부 내용은 직거래 서비스 약관에 의합니다.
                  </h5>
                </section>
                <section className="text-right">
                  한독자동차㈜ 대표이사 (인)
                </section>
              </div>
            </div>
          </div>
        </div>
        {/* 8페이지 */}
        {/* <div className="paper_container">
          <div className="paper_wrapper">
            <div className="paper">
              <div className="section_title_gray">
                18. 개인 당사자 간 거래를 위한 자동차 성능 상태 점검 보험 가입
                확인서
              </div>
              <div className="section_container">
                <h1>프리미엄 직거래 성능보증 서비스</h1>

                <section>
                  <h2>중고차 직거래 성능보증 서비스란?</h2>
                  <p>
                    중고차 직거래 보증 서비스는 개인 당사자 간 직거래를 위하여
                    한독자동차(주)에서 성능점검을 받은 점검 내용과 다른 부분의
                    실손 수리비를 보상하기 위하여 현대해상보험(주)과
                    한독성능장이 제공하는 보증 서비스입니다.
                  </p>
                </section>

                <section>
                  <h2>가입 대상 차량</h2>
                  <p>
                    한독성능장의 중고차 직거래 성능평가를 받은 차량에 한하여
                    성능보증 서비스에 무료로 가입됩니다. (이하 '보장 차량')
                  </p>
                </section>

                <section>
                  <h2>가입 제외 차량</h2>
                  <p>
                    주행거리 12만km 이상의 차량, 1톤 초과 화물차량, 버스 & 택시,
                    렌터카 및 리스 차량 등 영업 차량, 레이싱카, 퍼포먼스 차량,
                    시험테스트 차량, 특수 차량 및 이륜/삼륜 차량, 공공기관용
                    차량, 제조 12년(출고일 포함) 경과 차량은 서비스 대상에서
                    제외 됩니다.
                  </p>
                </section>

                <section>
                  <h2>보증기간</h2>
                  <p>
                    보증기간 : 중고차 성능평가일 기준 30일 또는 주행거리 2,000km
                    이내
                  </p>
                  <ol>
                    <li>
                      보증기간은 중고차 성능평가일을 시작일로 하여 기간과
                      주행거리 중 먼저 도래한 것을 보증기간의 만료로 간주합니다.
                    </li>
                    <li>
                      본 서비스 보증기간 30일 이내에 보장 차량이 직거래 되지
                      않을 경우 자동 종료되며, 보증기간 내 이전 등록된 경우도
                      해당 등록일을 포함하여 30일을 초과할 수 없습니다.
                    </li>
                  </ol>
                </section>

                <section>
                  <h2>보상한도</h2>
                  <p>
                    보장 차량의 품질점검 상태가 상이하여 해당 부품의
                    기능이상으로 차량정비업체에서 수리한 경우 다음과 같이 보상해
                    드립니다.
                    <br />
                    (자동차 검사 방법으로 점검이 불가한 경우는 원칙적으로
                    보증에서 제외됩니다)
                  </p>
                  <ol>
                    <li>국산차, 외제차 1회 한 입고 수리비 2천만원</li>
                    <li>
                      보장 차량의 품질점검과 보장 차량의 외판부위, 주요골격,
                      동일성, 용도변경의 상태가 상이한 경우 아래와 같이
                      보상합니다.
                    </li>
                    <table>
                      <colgroup>
                        <col width="13%" />
                        <col width="67%" />
                        <col width="10%" />
                        <col width="10%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th colSpan="2">구분</th>
                          <th>국산차</th>
                          <th>외산차</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td rowSpan="2">외판부위</td>
                          <td>
                            후드, 프론트휀더, 도어, 트렁크리드,
                            라디에이터서포트, 쿼터패널, 사이드실패널
                          </td>
                          <td className="text-center">300</td>
                          <td className="text-center">500</td>
                        </tr>
                        <tr>
                          <td className="text-left">루프패널</td>
                          <td className="text-center">400</td>
                          <td className="text-center">700</td>
                        </tr>
                        <tr>
                          <td rowSpan="2">주요골격</td>
                          <td>
                            프론트패널, 크로스멤버, 인사이드패널, 사이드멤버,
                            필러패널, 대쉬패널 트렁크플로어, 리어패널
                          </td>
                          <td className="text-center">300</td>
                          <td className="text-center">500</td>
                        </tr>
                        <tr>
                          <td className="text-left">휠하우스, 플로어패널</td>
                          <td className="text-center">400</td>
                          <td className="text-center">700</td>
                        </tr>
                      </tbody>
                    </table>
                    <li>
                      A/S보증한도를 초과한 수리 비용에 대하여는 고객이
                      부담하여야 합니다.
                    </li>
                  </ol>
                </section>
                <section>
                  <h2>고객 자기부담금</h2>
                  <p>
                    ① 1회 입고 수리당 - 10만원&nbsp;&nbsp; ② 외판부위, 주요
                    골격 - 10만원
                  </p>
                  <p>
                    예) 일반수리 100만원 수리비 발생 = 소유주 부담금 10만원 +
                    수리비 지원 90만원
                    <br />
                    외판골격 50만원 수리 발생 = 소유주 부담금 10만원 + 수리비
                    지원 40만원
                  </p>
                </section>
                <section>
                  <h2>보증조건</h2>
                  <ol>
                    <li>
                      중고차 성능평가서상의 사전 고지된 내용과 다른 상태의 부품
                      및 기능이 있을 경우 보증수리를 해드립니다.
                      <br />
                      (성능평가서상의 불량으로 표시된 부분은 수리 불가)
                    </li>
                    <li>
                      보증 조건에 해당하는 차량을 수리하여 사고 직전의 상태로
                      원상회복하는데 소요되는 부품과 비용을 보상합니다.
                      <br />
                      (자동차 부품 및 수리 인건비 등을 포함한 직접비용에 한함)
                    </li>
                    <li>
                      보증수리 부품은 재생품을 원칙으로 하고 고객이 신품으로
                      교환을 원할 경우 신품과 재생품의 차액은 고객이 부담해야
                      합니다.
                    </li>
                    <li>
                      A/S 접수 시 안내된 정비업체에서 수리가 진행됨을 원칙으로
                      하며, 타 정비업체에서 임의 수리하여 가중되는 비용은 본인이
                      부담하여야 합니다.
                    </li>
                  </ol>
                </section>
              </div>
            </div>
          </div>
        </div> */}
        {/* 9페이지 */}
        {/* <div className="paper_container">
            <div className="paper_wrapper">
              <div className="paper">
                <div className="section_container">
                  <section>
                    <h2>보증범위</h2>
                    <p>아래의 항목에 대하여 보증수리 하여 드립니다</p>
                    <table className="none">
                      <thead>
                        <tr>
                          <th>구분</th>
                          <th>세부 항목</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>동일성</td>
                          <td>
                            차대번호 및 원동기형식 표기를 상이하게 점검한 경우
                          </td>
                        </tr>
                        <tr>
                          <td>튜닝</td>
                          <td>
                            각 장치에 대한 불법 튜닝을 상이하게 점검한 경우
                          </td>
                        </tr>
                        <tr>
                          <td>용도변경</td>
                          <td>렌트 및 영업용 이력을 상이하게 점검한 경우</td>
                        </tr>
                        <tr>
                          <td>자기진단사항</td>
                          <td>
                            <ul>
                              <li>
                                엔진 : 흡입공기유량센서,
                                공전속도제어장치(ETS제어), 냉각수온도센서,
                                스로틀위치센서(TPS), 산소센서, 크랭크각위치센서,
                                흡기온도센서
                              </li>
                              <li>
                                변속기 : 인히비터스위치, 입력축속도센서A,
                                출력축속도센서B, 자동변속기 관련 솔레노이드밸브
                              </li>
                              <li>
                                실린더헤드 : 실린더 헤드 및 그 내부 부품 /
                                개스킷, 실린더 커버(로커암 커버)
                              </li>
                              <li>
                                실린더블럭 : 실린더 블록 및 그 내부 부품 /
                                개스킷, 오일팬
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>원동기</td>
                          <td>
                            <ul>
                              <li>냉각장치 : 워터펌프, 엔진룸 라디에이터</li>
                              <li>
                                고압연료분사장치 : 커먼레일,
                                고압연료파이프(고압펌프, 인젝터 제외),
                                연료압력조절밸브, EGR밸브
                              </li>
                            </ul>
                            ※ 미세누유 또는 미세누수로 표기한 경우 위 부품이
                            고장 난 경우에 한하여 보증
                            <br />※ 누유 또는 누수로 표기한 경우 누유 또는
                            누수를 원인으로 하는 위 부품 고장시 보증 제외
                          </td>
                        </tr>
                        <tr>
                          <td>동력전달</td>
                          <td>
                            클러치 어셈블리(클러치판 제외), 추진축 및 베어링,
                            등속조인트, 드라이브샤프트, 디퍼렌셜기어
                          </td>
                        </tr>
                        <tr>
                          <td>변속기</td>
                          <td>
                            <ul>
                              <li>
                                자동변속기(A/T) : 토크컨버터, 변속기케이스,
                                유성기어, 디퍼렌셜기어, 유온센서,
                                콘트롤밸브바디(내부클러치, 브레이크제외)
                              </li>
                              <li>
                                수동변속기(M/T) : 변속기케이스,
                                변속기어어셈블리, 디퍼렌셜기어
                              </li>
                            </ul>
                            ※ 미세누유 또는 미세누수로 표기한 경우 위 부품이
                            고장 난 경우에 한하여 보증
                            <br />※ 누유 또는 누수로 표기한 경우 누유 또는
                            누수를 원인으로 하는 위 부품 고장시 보증 제외
                          </td>
                        </tr>
                        <tr>
                          <td>조향</td>
                          <td>
                            스티어링펌프, 스티어링기어(MDPS포함),
                            스티어링조인트, 파워고압호스, 타이로드엔드 및
                            볼조인트
                          </td>
                        </tr>
                        <tr>
                          <td>제동</td>
                          <td>
                            마스터실린더, 휠실린더(캘리퍼), 브레이크 호스 및
                            파이프, 배력장치, 진공펌프, 브레이크압력센서
                          </td>
                        </tr>
                        <tr>
                          <td>전기</td>
                          <td>
                            발전기, 와이퍼모터, 송풍모터, 라디에이터팬모터,
                            시동모터, 윈도우모터
                          </td>
                        </tr>
                        <tr>
                          <td>고전원 전기장치</td>
                          <td>
                            충전구 절연상태, 구동축전지 격리상태,
                            고전원전기배선(절속단자, 피복, 보호기구) 상태
                          </td>
                        </tr>
                        <tr>
                          <td>연료</td>
                          <td>
                            연료호스 및 파이프, 믹서, 기화기(베이퍼라이저),
                            솔레노이드밸브, 연료게이지센서
                          </td>
                        </tr>
                        <tr>
                          <td>외판부위</td>
                          <td>
                            후드, 프론트휀더, 도어, 트렁크리드, 쿼터패널,
                            루프패널, 사이드실패널
                            <br />
                            ※품질, 요철, 손상 및 자동차 관리법령상
                            자동차정비업의 제외사항에 해당하는 경미한
                            판금·도색은 제외
                          </td>
                        </tr>
                        <tr>
                          <td>주요골격 부위</td>
                          <td>
                            프론트패널, 크로스멤버(볼트체결부품 제외),
                            인사이드패널, 트렁크플로어(볼트체결부품 제외),
                            리어패널, 사이드멤버, 휠하우스, 필러패널, 대쉬패널,
                            플로어패널, 루프레일
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                  <section className="none">
                    <h2>보증수리보상 제외</h2>
                    <p>
                      아래의 사유로 생긴 사고(손해)는 보상하여 드리지 않습니다.
                      <br />
                      ※사고(손해) : A/S 보증법위에 해당하는 부품의 고장으로 A/S
                      접수 시 안내된 정비업체에서 보장차량의 수리가 이루어진
                      경우를 의미
                    </p>
                    <ol>
                      <li>
                        서비스 이용자(보장차량 운행자 포함)의 고의로 생긴
                        사고(손해)로 인한 수리 비용
                      </li>
                      <li>
                        법률상 배상책임이 있는 제3자의 고의 또는 과실로 인한
                        사고로 발생한 수리 비용
                      </li>
                    </ol>
                  </section>
                </div>
              </div>
            </div>
          </div> */}
        {/* 10페이지 */}
        {/* <div className="paper_container">
          <div className="paper_wrapper">
            <div className="paper">
              <div className="section_container">
                <section className="none">
                  <ol start="3">
                    <li>
                      자동차 제작사(제조사)의 보증(수리 보장) 프로그램(해당
                      보증은 리콜을 포함합니다)이나 그 이외의 보증 또는 수리
                      보장 프로그램(자동차 매매업자 등 제3자의 보증 또는 보장을
                      포함합니다)에서 보장하는 사고(손해)
                    </li>
                    <li>
                      일상점검의 소홀로 인한 냉각수 부족,
                      엔진오일부족(트랜스미션) 오일 부족 등으로 인한 고장 및
                      미모
                    </li>
                    <li>차량의 변형 및 개조에 의한 고장</li>
                    <li>도난으로 인한 수리 상황이 발생한 경우</li>
                    <li>
                      유상 운송 혹은 영리를 목적으로 사용하다 대가를 받고 운행
                      중 발생한 사고(손해)
                    </li>
                    <li>
                      소모품 손해. 다만, 부품의 수리 시 소모품 교체 또는 교환이
                      필수적으로 발생하는 경우에는 회사의 사전동의를 받은 경우에
                      한하여 해당 소모품 사고(손해)를 보상하여 드립니다
                    </li>
                    <li>
                      품질 및 기능상 영향이 없는 감각적 손해(소음, 진동, 냄새,
                      외관, 작동 감각 등)
                    </li>
                    <li>
                      다른 차량 또는 다른 물체와의 충돌, 접촉, 추락, 전복으로
                      인한 사고 및 자동차보험(공제계약을 포함합니다)의 보장
                      대상이 되는 사고(보험·공제계약의 가입 여부 및 실제 보험금
                      지급 여부와는 관계가 없습니다)
                    </li>
                    <li>
                      차량 제작사의 운행 능력한계를 초과한 무리한 운행, 적재량
                      초과, 수리 지연에 의한 고장
                    </li>
                    <li>
                      차량 제작사에서 지정한 연료 이외에 유사 연료 또는 첨가제
                      등을 사용하여 발생한 고장
                    </li>
                    <li>
                      차량 제작사에 의하지 않은 차량의 LPG 개조로의 변경으로
                      인한 사고(손해)
                    </li>
                    <li>
                      보장 차량의 고장으로 인한 간접비용, 차량 대체비용(렌트
                      비용 등), 휴업손해 등 간접비용
                    </li>
                    <li>
                      차량 출고 시 장착 사양과 다르게 임의로 교체 장착한 부품의
                      고장 및 그 부품으로 연관되어 발생한 사고(손해)
                    </li>
                    <li>자동차보험의 보험금 지급기록이 있는 침수 사고(손해)</li>
                    <li>회사가 지정하지 않은 수리정비업체에 의한 수리 비용</li>
                    <li>
                      주행거리계의 고장, 임의 변조 또는 교체로 정확한 주행거리를
                      확인할 수 없는 경우
                    </li>
                    <li>
                      보장 차량의 고장이 발생한 날 당시 주행거리가 보증 서비스
                      증권상 기재된 주행거리 조건을 초과한 경우
                    </li>
                    <li>
                      수리 비용의 합계액이 보증 서비스 증권에 기재된
                      보상한도액을 초과하는 경우 그 초과 금액
                    </li>
                    <li>
                      보장 차량이 자동차 관리 정도 이외로 사용되거나 경찰 차량
                      또는 유사 차량(구급차, 견인차, 구난차, 장의차 등)으로
                      사용되어 발생한 사고(손해)
                    </li>
                    <li>
                      핵연료 물질 또는 핵연료 물질에 의하여 오염된 물질의
                      방사성, 폭발성 또는 그 밖의 유해한 특성에 의한 사고로 생긴
                      사고(손해)
                    </li>
                    <li>
                      위 제22조 이외의 방사선을 쬐는 것 또는 방사능 오염으로
                      인한 사고(손해)
                    </li>
                    <li>
                      전쟁, 혁명, 내란, 사변, 테러, 폭동, 소요, 노동쟁의 기타
                      이들과 유사한 사태로 생긴 사고(손해)
                    </li>
                    <li>
                      지진, 분화, 홍수, 해일 또는 이와 비슷한 천재지변으로 생긴
                      사고(손해)
                    </li>
                    <li>
                      화재, 폭발, 날아온 물체, 떨어지는 물체에 의한 사고(손해)
                    </li>
                    <li>
                      각종 오일이나 연료의 침전, 오염 및 부식 또는 기후와 관련된
                      변형으로 인한 고장
                    </li>
                    <li>
                      서비스 안내서에서 담보하는 부품 외의 부품 사고(손해)
                    </li>
                    <li>
                      지정정비업체의 수리 과정상의 하자(부주의나 교체한 부품의
                      결함)로 야기된 사고(손해)
                    </li>
                    <li>
                      정상적인 차량 관리, 즉 전차륜 정렬, 휠 발란스, 휠
                      얼라인먼트, 엔진튠업, 브레이크 점검 및 조정 등 기타 제조사
                      매뉴얼 등에서 권장하는 작업
                    </li>
                    <li>
                      국가나 공공단체의 공권력 행사에 의한 압류, 징발, 몰수,
                      파괴 등으로 인한 고장
                    </li>
                    <li>
                      차량의 일부 부분품, 부속품, 부속 기계장치만의 도난으로
                      인한 고장
                    </li>
                    <li>
                      경주, 등판능력, 속도 및 내구시험 등에 발생한 사고(손해)
                    </li>
                    <li>품질평가서에 기재된 하자 불량 및 이로 인한 고장</li>
                    <li>벌과금 및 징벌적 손해</li>
                    <li>보장 차량을 해외에서 수리하는 경우</li>
                    <li>보증수리가 불가한 보증 부품에 대한 재수리</li>
                    <li>
                      서비스 보장개시 이전에 이미 발생한 결함으로 인한 고장
                    </li>
                    <li>
                      사고 및 보상한도를 초과한 범위의 동일사고·동일 부품으로
                      발생 수리 및 중복으로 청구하는 경우
                    </li>
                    <li>침수차량 및 전손사고 차량</li>
                    <li>수리업소까지 이동한 견인비</li>
                  </ol>
                </section>
                <section>
                  <h2>철회/무효/해제/취소/해지 규정</h2>
                  <h5>계약의 철회/무효/해제/취소/해지</h5>
                  <ol>
                    <li>
                      서비스계약을 맺을 때 계약 목적의 사고가 이미 발생하였을
                      경우 이 계약은 무효로 합니다.
                    </li>
                    <li>
                      회사는 계약자 또는 이들의 대리인이 입력한 계약 사항이
                      사실과 다른 경우에 계약을 해제할 수 있습니다.
                    </li>
                    <li>
                      회사는 아래의 중대한 사유의 경우 계약을 해지할 수
                      있습니다.
                      <ul>
                        <li>계약자의 고의로 손해가 발생한 경우</li>
                        <li>
                          계약자가 보증 서비스 청구에 관한 서류에 고의로 사실과
                          다른 것을 기재하였거나 그 서류 또는 증거를 위조 또는
                          변조한 경우
                        </li>
                      </ul>
                    </li>
                  </ol>
                  <h5>직거래 품질보증 서비스 절차</h5>
                  <p>
                    [전화 상담 및 접수 : ○○○○ - ○○○○ ]<br />
                    서비스 → 서비스 접수 → 지정정비소 입고 → 서비스 여부 확인 →
                    처리 완료 및 차량출고
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div> */}

        {/* {payment &&
          _.get(isAvailable, 'certificate') &&
          renderInsuranceCertificate(_.get(payment, 'ICNY_CODE'))}

        {payment &&
          _.get(isAvailable, 'ew') &&
          renderEwInsuranceCertificate(_.get(payment, 'ICNY_CODE'))} */}
      </div>
    );
  }
);

export default PerformanceCheckReport;
