import React from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';

export const GroupTitledCheckRadioText = ({
  control,
  title,
  name,
  items,
  defaultValues,
}) => {
  return (
    <div className="p-inputgroup h-full">
      <span className="p-inputgroup-addon font-medium text-center w-3">
        {title}
      </span>
      <div className="p-inputgroup-addon bg-transparent w-full">
        <div className="flex align-items-center gap-3 w-full flex-column lg:flex-row">
          <div className="col-12 lg:col-3">
            <div className="flex gap-2 w-full">
              <CheckboxGroup
                control={control}
                name={name}
                items={items}
                defaultValue={defaultValues[name]}
              />
            </div>
          </div>
          <Controller
            control={control}
            name={`${name}Note`}
            defaultValue={defaultValues[`${name}Note`]}
            render={({ field }) => (
              <InputText
                className="w-full"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                placeholder="의견란"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

const CheckboxGroup = ({ control, name, items, defaultValue }) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <>
          {items.map((item, idx) => (
            <div
              key={`${name}_${item.value}_div`}
              className="flex items-center gap-1"
            >
              <Checkbox
                inputId={`${name}_${item.value}`}
                name={`${item.label}.option`}
                value={item.value}
                onChange={(e) => {
                  field.onChange(e.checked ? item.value : '');
                }}
                checked={field.value === item.value}
              />
              <label htmlFor={`${name}_${item.value}`}>{item.label}</label>
            </div>
          ))}
        </>
      )}
    />
  );
};
