import React, { useEffect, useState, useMemo } from 'react';
import { COUNT_PER_PAGE } from '../../constants/Constants';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import ReportDialog from './ReportDialog';
import { useQuery } from 'react-query';
import { Claim } from '../../services/ClaimService';
import dayjs from 'dayjs';
import RangeCalendar from '../../components/Common/RangeCalendar';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';
import { touchUIState } from '../../recoil/atoms';
import { useHistory } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';
import { CommonUtil } from '../../utils/commonUtil';
import SendSMS from './SendSMS';
import { ServiceProvider } from '../../services/index';

const partnerService = ServiceProvider.partner;

const ClaimList = () => {
  const history = useHistory();
  const touchUI = useRecoilValue(touchUIState);
  const [showReportMoal, setShowReportModal] = useState(false);
  const [showSmsMoal, setShowSmsModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const today = dayjs();
  const startDate = today.subtract(1, 'month');
  const endDate = today;
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const partner = myInfoLoadable.contents.roleCode === 'D_ADM';

  const [customerInfo, setCustomerInfo] = useState({
    carNum: '',
    carVIN: '',
    customerName: '',
    customerContact: '',
    association: { value: '' },
    warranty: { value: '' },
    funnelsType: 'W', // W 인경우 call, A 인경우 앱
  });

  const [carDetailData, setCarDetailData] = useState(null);

  const [searchData, setSearchData] = useState({
    startDate: startDate.toDate(),
    endDate: endDate.toDate(),
    carNbr: '',
    customerName: '',
    customerContact: '',
    partnerName: '',
    partnerContact: '',
    checkType: '',
  });
  const [loading, setLoading] = useState(false);
  const defaultData = {
    startDate: dayjs(startDate).format('YYYYMMDD'),
    endDate: dayjs(endDate).format('YYYYMMDD'),
    page: lazyState.page + 1,
    size: lazyState.rows,
  };

  const [searchRadio, setSearchRadio] = useState({
    inputId: 'customer',
    value: 'customer',
  });
  const [searchMode, setSearchMode] = useState(false);
  const [statusCode, setStatusCode] = useState('');

  const { data, isLoading } = useQuery({
    queryKey: ['list', searchMode, lazyState.page, lazyState.rows],
    queryFn: () => Claim.getList(defaultData),
    enabled: !searchMode,
    refetchInterval: 30000, // 30초 간격
  });

  const getSearchClaimList = async () => {
    try {
      const data = await Claim.getList({
        ...searchData,
        startDate: dayjs(searchData.startDate).format('YYYYMMDD'),
        endDate: dayjs(searchData.endDate).format('YYYYMMDD'),
        page: lazyState.page + 1,
        size: lazyState.rows,
      });
      if (data) {
        setTableData(data.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data && !isLoading && !searchMode) {
      setTableData(data.list);
      setSearchMode(false);
    }
  }, [data, isLoading, searchMode]);

  const handleCustomerInfoInput = (event) => {
    const target = event.target;
    const { name, value } = target;
    setCustomerInfo({ ...customerInfo, [name]: value });
  };

  const handleSearchInput = (event) => {
    const target = event.target;
    const { name, value } = target;
    setSearchData({ ...searchData, [name]: value });
  };

  const handleRadio = (event) => {
    const target = event.target;
    setSearchRadio({
      inputId: target.name,
      value: target.value,
    });
  };

  const resetSearch = () => {
    setSearchData({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      carNbr: '',
      customerName: '',
      customerContact: '',
      partnerName: '',
      partnerContact: '',
      checkType: '',
    });
    setSearchRadio({
      inputId: 'customer',
      value: 'customer',
    });
    setStatusCode('');
    setSearchMode(false);
  };

  const checkWarranty = async (carNum, VIN) => {
    // if (VIN && VIN.length < 16) {
    //   return window.cerp.toast.warn('차대번호 17자리를 입력해주세요.');
    // }
    setLoading(true);
    try {
      const data = await Claim.getCheckWarranty(carNum, VIN);
      const info = JSON.parse(data.performanceCheckData.paymentInformation);
      if (data) {
        setLoading(false);
        setCustomerInfo({
          ...customerInfo,
          association: { value: 'association' },
          warranty: {
            value: info.ASSRNC_TY_SE_CODE === '1' ? 'warranty_s' : 'warranty',
          },
        });
        setCarDetailData(data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      window.cerp.toast.warn(
        '보증 불가',
        `${error.response.data.error?.message}`
      );
      setCustomerInfo({
        ...customerInfo,
        association: { value: 'non_association' },
      });
    }
  };

  useEffect(() => {
    if (customerInfo.carNum === '') {
      setCustomerInfo({
        ...customerInfo,
        association: { value: '' },
        warranty: { value: '' },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo.carNum]);

  const { data: status } = useQuery({
    queryKey: ['status'],
    queryFn: () => Claim.getDocsType('Z'),
    cacheTime: 86400000,
  });
  const statusList = useMemo(() => status?.data || [], [status]);

  const [smsData, setSmsData] = useState({
    msgType: '',
    sourceAddr: '070-7576-4981',
    destAddr: '',
    subject: null, //opt,
    contents: '',
    fileData: null, //opt
  });

  useEffect(() => {
    if (customerInfo.customerName && customerInfo.customerContact) {
      setSmsData({ ...smsData, destAddr: customerInfo.customerContact });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo]);

  const postSms = async (title) => {
    setLoading(true);
    const type = smsData.contents.length > 80 ? 'lms' : 'sms';

    const postData = {
      msgType: type,
      sourceAddr: smsData.sourceAddr,
      destAddr: smsData.destAddr.replaceAll('-', ''),
      subject: title ? title : null, //opt,
      contents: smsData.contents,
      fileData: null, //opt
      url: null, // opt
    };
    // console.log(postData);
    try {
      const data = await Claim.postSMS(postData);
      if (data) {
        window.cerp.toast.info('메세지 전송이 완료되었습니다.');
        setShowSmsModal(false);
        setCustomerInfo({
          carNum: '',
          carVIN: '',
          customerName: '',
          customerContact: '',
          association: { value: '' },
          warranty: { value: '' },
          funnelsType: 'W', // W 인경우 call, A 인경우 앱
        });
      }
    } catch (error) {
      setShowSmsModal(false);
      console.log(error);
      window.cerp.toast.warn(error.response?.data.error?.message);
    }
    setLoading(false);
  };

  const [partnerAllList, setPartnerAllList] = useState([]);
  const getPartnerList = async () => {
    try {
      const data = await partnerService.list({
        aids: ['1'],
        page: 1,
        size: 99,
      });
      if (data) {
        setPartnerAllList(data.data.list);
        // console.log(data.data.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPartnerList();
  }, []);

  return (
    <div>
      <BlockUI
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        {partner ? null : (
          <Panel className="shadow-1">
            <div className="flex justify-content-between align-items-center">
              <div className="font-bold">클레임 접수</div>
            </div>

            <div className="grid mt-2">
              <div className="col-6">
                <Panel header="1. 보증 가능 여부 조회">
                  <div className="grid col-12">
                    <div className="col-10">
                      <div className="field grid align-items-center">
                        <div className="col-2 font-bold">
                          <label htmlFor="">차량번호</label>
                        </div>
                        <div className="col">
                          <InputText
                            value={customerInfo.carNum}
                            onChange={(e) => handleCustomerInfoInput(e)}
                            className="w-full mr-2"
                            name="carNum"
                            placeholder="차량번호 입력"
                          />
                        </div>
                      </div>
                      <div className="field grid align-items-center mb-0">
                        <div className="col-2 font-bold">
                          <label htmlFor="">차대번호</label>
                        </div>
                        <div className="col">
                          <InputText
                            placeholder="차대번호 17자리 입력"
                            value={customerInfo.carVIN}
                            onChange={(e) => handleCustomerInfoInput(e)}
                            className=" w-full"
                            name="carVIN"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-2">
                      <Button
                        type="button"
                        label="조회"
                        // icon="pi pi-search"
                        onClick={() =>
                          customerInfo.carNum || customerInfo.carVIN
                            ? checkWarranty(
                                customerInfo.carNum,
                                customerInfo.carVIN
                              )
                            : window.cerp.toast.warn(
                                '차량번호 또는 차대번호를 입력하세요'
                              )
                        }
                        className="w-full h-6rem mt-1"
                      />
                    </div>
                  </div>
                  <Divider />
                  <div className="p-1">
                    <div className="field grid align-items-center">
                      <div className="col-2 font-bold ">
                        <label htmlFor="">보증가능</label>
                      </div>
                      <div className="col-7">
                        <div className="flex justify-content-between">
                          <div className="flex align-items-center gap-2">
                            <RadioButton
                              inputId="association"
                              name="association"
                              checked={
                                customerInfo.association.value === 'association'
                              }
                            />
                            <label htmlFor="association">협회 진단차량</label>
                            <RadioButton
                              inputId="non_association"
                              name="non_association"
                              checked={
                                customerInfo.association.value ===
                                'non_association'
                              }
                            />
                            <label htmlFor="non_association">미 진단차량</label>
                          </div>
                        </div>
                      </div>
                      {customerInfo.association.value === 'non_association' && (
                        <div className="col text-red-600">보증불가</div>
                      )}
                    </div>
                    <div className="field grid align-items-center">
                      <div className="col-2 font-bold">
                        <label htmlFor="">보증구분</label>
                      </div>
                      <div className="col-7">
                        <div className="flex justify-content-between">
                          <div className="flex align-items-center gap-2">
                            <RadioButton
                              inputId="warranty"
                              name="warranty"
                              value="warranty"
                              checked={
                                customerInfo.warranty.value === 'warranty'
                              }
                            />
                            <label htmlFor="warranty">보험사보증</label>
                            <RadioButton
                              inputId="warranty_s"
                              name="warranty_s"
                              value="warranty_s"
                              checked={
                                customerInfo.warranty.value === 'warranty_s'
                              }
                            />
                            <label htmlFor="warranty_s">보험사비보증</label>
                          </div>
                        </div>
                      </div>
                      {customerInfo.warranty.value === 'warranty_s' && (
                        <div className="col text-red-600">보증불가</div>
                      )}
                    </div>
                  </div>
                </Panel>
              </div>
              <div className="col-6">
                <Panel header="2. 기본 정보">
                  <div className="field grid align-items-center">
                    <div className="col-2 font-bold">
                      <label htmlFor="">고객명</label>
                    </div>
                    <div className="col">
                      <InputText
                        value={customerInfo.customerName}
                        onChange={(e) => handleCustomerInfoInput(e)}
                        className=" w-full"
                        name="customerName"
                        placeholder="(필수) 고객명 입력"
                      />
                    </div>
                  </div>
                  <div className="field grid align-items-center mb-0">
                    <div className="col-2 font-bold">
                      <label htmlFor="">고객연락처</label>
                    </div>
                    <div className="col">
                      <InputText
                        value={CommonUtil.Formatter.phone(
                          customerInfo.customerContact
                        )}
                        onChange={(e) => handleCustomerInfoInput(e)}
                        className=" w-full"
                        name="customerContact"
                        placeholder="(필수) 연락처 입력"
                      />
                    </div>
                    <Button
                      type="button"
                      label="SMS 전송"
                      icon="pi pi-envelope"
                      onClick={
                        customerInfo.customerName &&
                        customerInfo.customerContact
                          ? () => {
                              setShowSmsModal(true);
                            }
                          : () =>
                              window.cerp.toast.warn(
                                '고객명과 연락처를 입력하세요.'
                              )
                      }
                    />
                  </div>
                </Panel>

                <div className="flex mt-4 justify-content-end">
                  <Button
                    type="button"
                    label="클레임 등록"
                    icon="pi pi-pencil"
                    className=""
                    onClick={() =>
                      carDetailData?.performanceCheckData?.statementNumber
                        ? history.push({
                            pathname: '/compensation/accident/claim/detail',
                            state: { customerInfo, carDetailData },
                          })
                        : window.cerp.toast.warn(
                            '보증가능 여부 조회를 먼저 해주세요.'
                          )
                    }
                  />
                </div>
              </div>
            </div>
          </Panel>
        )}
      </BlockUI>
      {partner ? null : (
        <Panel className="shadow-1 mt-3">
          <div className="grid">
            <div className="col-2">
              <div className="mb-2">
                <label htmlFor="associations">업무처리상태</label>
              </div>
              <Dropdown
                value={statusCode}
                onChange={(e) => {
                  setStatusCode(e.target.value);
                  setSearchData({ ...searchData, checkType: e.value });
                }}
                options={statusList}
                optionLabel="label"
                placeholder="상태 선택"
                className="w-full"
              />
            </div>
            <div className="col-6">
              <div className="mb-2">
                <label htmlFor="associations">기간</label>
              </div>
              <RangeCalendar
                startDate={searchData.startDate}
                endDate={searchData.endDate}
                onStartDateChanged={(date) => {
                  setSearchData((ps) => ({
                    ...ps,
                    startDate: date,
                  }));
                }}
                onEndDateChanged={(date) => {
                  setSearchData((ps) => ({
                    ...ps,
                    endDate: date,
                  }));
                }}
                touchUI={touchUI}
                showNavigators={true}
              />
            </div>
          </div>

          <div className="grid mt-2">
            <div className="col-2">
              <div className="mb-2">구분</div>
              <RadioButton
                inputId="customer"
                name="customer"
                value="customer"
                className="mr-2"
                onChange={(e) => handleRadio(e)}
                checked={searchRadio.value === 'customer'}
              />
              <label htmlFor="customer">고객</label>
              <RadioButton
                inputId="partner"
                name="partener"
                value="partener"
                className="ml-4 mr-2"
                onChange={(e) => handleRadio(e)}
                checked={searchRadio.value === 'partener'}
              />
              <label htmlFor="partner">진단점</label>
            </div>
            <div className="col-3">
              <div className="mb-2">
                <label htmlFor="">이름</label>
              </div>
              <InputText
                value={
                  searchRadio.inputId === 'customer'
                    ? searchData.customerName
                    : searchData.partnerName
                }
                onChange={(e) => handleSearchInput(e)}
                className="w-full"
                name={
                  searchRadio.inputId === 'customer'
                    ? 'customerName'
                    : 'partnerName'
                }
                placeholder={
                  searchRadio.inputId === 'customer'
                    ? '고객명 입력'
                    : '진단점명 입력'
                }
              />
            </div>
            <div className="col-3">
              <div className="mb-2">
                <label htmlFor="">연락처</label>
              </div>
              <InputText
                value={
                  searchRadio.inputId === 'customer'
                    ? searchData.customerContact
                    : searchData.partnerContact
                }
                onChange={(e) => handleSearchInput(e)}
                className="w-full"
                name={
                  searchRadio.inputId === 'customer'
                    ? 'customerContact'
                    : 'partnerContact'
                }
                placeholder={
                  searchRadio.inputId === 'customer'
                    ? '고객 연락처 입력'
                    : '진단점 연락처 입력'
                }
              />
            </div>
            <div className="col-3">
              <div className="mb-2">
                <label htmlFor="">차량번호</label>
              </div>
              <InputText
                value={searchData.carNbr}
                onChange={(e) => handleSearchInput(e)}
                className="w-full"
                name="carNbr"
                placeholder="차량번호 입력"
              />
            </div>
          </div>
          <div className="flex gap-4 mt-3">
            <Divider />
          </div>
          <div className="flex justify-content-end">
            <Button
              label="검색조건 초기화"
              icon="pi pi-undo"
              className="p-button-outlined mr-1"
              onClick={resetSearch}
            />
            <Button
              label="검색"
              icon="pi pi-search"
              onClick={() => {
                setSearchMode(true);
                getSearchClaimList();
              }}
            />
          </div>
        </Panel>
      )}
      <Panel className="shadow-1 mt-3 mb-3">
        <DataTable
          value={tableData}
          lazy
          onPage={(e) => setlazyState(e)}
          rows={lazyState.rows}
          first={lazyState.first}
          totalRecords={data?.total}
          paginator
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
          rowsPerPageOptions={COUNT_PER_PAGE}
          showGridlines
          emptyMessage="데이터가 없습니다."
          loading={isLoading}
          // size="small"
          onSelectionChange={({ value }) => {
            setSelectedRows(value);
            history.push({
              pathname: '/compensation/accident/claim/detail',
              state: { customerInfo: value },
            });
          }}
          dataKey="id"
          selection={selectedRows}
          selectionMode="single"
          stripedRows
        >
          <Column
            align={'center'}
            field="dtn"
            header="순번"
            body={(dataId, column) => (
              <div className="w-2rem">{column.rowIndex + 1}</div>
            )}
          />
          <Column
            align={'center'}
            field={'funnelsType'}
            header={'접수방법'}
            body={({ funnelsType }) => (
              <div className="w-4rem">
                {funnelsType === 'W' ? '전화' : '앱'}
              </div>
            )}
          />
          <Column
            align={''}
            field={'claimStatus'}
            header={'업무처리상태'}
            body={({ claimStatus }) => (
              <div className="w-14rem">
                {statusList.find((el) => el.value === claimStatus)?.label}
              </div>
            )}
          />
          <Column
            align={'center'}
            field={'claimStatus'}
            header={'접수일자'}
            body={({ receiptDate }) => (
              <div className="w-8rem">
                {CommonUtil.Formatter.stringToDayForm(receiptDate)}
              </div>
            )}
          />
          {TABLE_COLUMNS.map((col, i) => (
            <Column
              align={'center'}
              key={i}
              field={col.field}
              header={<div className="w-10rem">{col.header}</div>}
            />
          ))}
          <Column
            align={'center'}
            field={'partnerId'}
            header={'진단점'}
            body={({ partnerId }) => {
              const ids = partnerId?.split(',');
              const data = [];
              ids &&
                ids.forEach((id) =>
                  data.push(
                    partnerAllList.filter((el) => Number(id) === el.partnerId)
                  )
                );
              return (
                <div className="w-9rem">
                  {data
                    ?.flat()
                    .map((el, idx) =>
                      data.length === idx + 1
                        ? el.partnerName
                        : el.partnerName + ', '
                    )}
                </div>
              );
            }}
          />
          <Column
            align={'center'}
            field={'partnerId'}
            header={'진단점 연락처'}
            body={({ partnerId }) => {
              const ids = partnerId?.split(',');
              const data = [];
              ids &&
                ids.forEach((id) =>
                  data.push(
                    partnerAllList.filter((el) => Number(id) === el.partnerId)
                  )
                );
              return (
                <div className="w-9rem">
                  {data
                    ?.flat()
                    .map((el, idx) =>
                      data.length === idx + 1
                        ? el.cellphone
                        : el.cellphone + ', '
                    )}
                </div>
              );
            }}
          />
          {partner ? null : (
            <Column
              align={'center'}
              field={'insurancePurchaseDate'}
              header={'보험료지급일'}
              body={({ insurancePurchaseDate }) => (
                <div className="w-8rem">
                  {insurancePurchaseDate
                    ? CommonUtil.Formatter.stringToDayForm(
                        insurancePurchaseDate
                      )
                    : '미지급'}
                </div>
              )}
            />
          )}
        </DataTable>
      </Panel>

      {showSmsMoal && (
        <SendSMS
          showSmsType="APP_DOWNLOAD"
          smsData={smsData}
          setSmsData={setSmsData}
          customerInfo={customerInfo}
          onHide={() => setShowSmsModal(!showSmsMoal)}
          postSms={postSms}
        />
      )}
      {showReportMoal && (
        <ReportDialog onHide={() => setShowReportModal(!showReportMoal)} />
      )}
    </div>
  );
};

export default ClaimList;

const TABLE_COLUMNS = [
  { field: 'carNbr', header: '차량번호' },
  { field: 'carName', header: '차명' },
  { field: 'customerName', header: '고객명' },
  { field: 'customerContact', header: '고객연락처' },
  { field: 'carAddress', header: '고객차량위치' },
  // { field: 'partnerName', header: '진단점' },
  // { field: 'partnerContact', header: '진단점연락처' },
];
